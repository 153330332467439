<template>
  <div
    class="doctor-detail-container"
    v-loading="isloading"
    :element-loading-text="loadingText || '数据上传中'"
  >
    <item-scroll ref="itemScroll">
      <div class="doctor-detail-main">
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.name">*</span>姓名</div>
          <div
            class="global_text_input doctor-detail-value doctor-detail-name"
            v-if="doctorInfo.id"
          >
            <el-input
              v-model.trim="doctorInfo.name"
              placeholder="请输入医生姓名"
              disabled
            ></el-input>
          </div>
          <div class="global_text_input doctor-detail-value doctor-detail-name2" v-else>
            <el-input
              @input="inputField('name')"
              v-model.trim="doctorInfo.name"
              placeholder="请输入医生姓名"
              disabled
            ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.hospital">*</span>医院</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @blur="focusout('hospital')"
              @input="inputField('hospital')"
              v-model.trim="doctorInfo.hospital"
              placeholder="请输入医院名称"
            ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.province">*</span>省份（示例：湖北）</div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select
              v-model="doctorInfo.province"
              placeholder="请选择所在省份"
              @change="changeProvince()"
            >
              <el-option
                v-for="(item, index) in provinces"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.city">*</span>城市（示例：武汉）</div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select v-model="doctorInfo.city" placeholder="请选择所在城市">
              <el-option
                v-for="(item, index) in citys"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.phone">*</span>手机号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @blur="focusout('phone')"
              @input="inputField('phone')"
              v-model.trim="doctorInfo.phone"
              placeholder="请输入手机号"
            ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.id_card_number">*</span>身份证号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @blur="focusout('id_card_number')"
              @input="inputField('id_card_number')"
              v-model.trim="doctorInfo.id_card_number"
              placeholder="请输入身份证号"
            ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!(doctorInfo.id_card_front && doctorInfo.id_card_reverse)">*</span>身份证照片</div>
          <div class="doctor-detail-value global_container_center">
            <div
              class="doctor-detail-image-block"
              @click="selectFile('id_card_front')"
            >
              <img
                :src="doctorInfo.id_card_front"
                v-if="doctorInfo.id_card_front && !upload.id_card_front"
                alt=""
              />
              <div class="doctor-detail-image-select" v-else>
                <i v-show="!doctorInfo.id_card_front" class="el-icon-plus"></i>
                <span>{{
                  getShowName("id_card_front", "上传身份证正面")
                }}</span>
              </div>
            </div>
          </div>
          <div class="doctor-detail-value global_container_center">
            <div
              class="doctor-detail-image-block"
              @click="selectFile('id_card_reverse')"
            >
              <img
                :src="doctorInfo.id_card_reverse"
                v-if="doctorInfo.id_card_reverse && !upload.id_card_reverse"
                alt=""
              />
              <div class="doctor-detail-image-select" v-else>
                <i
                  v-show="!doctorInfo.id_card_reverse"
                  class="el-icon-plus"
                ></i>
                <span>{{
                  getShowName("id_card_reverse", "上传身份证反面")
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.bank_name">*</span>银行（示例：交通银行）
          </div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @input="inputField('bank_name')"
              v-model.trim="doctorInfo.bank_name"
              placeholder="请输入银行"
            ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.bank_province">*</span>开户行所在省份（示例：湖北）
          </div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select
              v-model="doctorInfo.bank_province"
              placeholder="请选择开户行所在省份"
              @change="changeProvince('bank')"
            >
              <el-option
                v-for="(item, index) in provinces"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.bank_city">*</span>开户行所在城市（示例：武汉）
          </div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select
              v-model="doctorInfo.bank_city"
              placeholder="请选择开户行所在城市"
            >
              <el-option
                v-for="(item, index) in bankCitys"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.bank_name_point">*</span>开户银行（xx支行/xx营业部）
          </div>
          <div class="global_text_input doctor-detail-value">
            <el-input
                @input="inputField('bank_name_point')"
                v-model.trim="doctorInfo.bank_name_point"
                placeholder="请输入开户银行"
            ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.bank_number">*</span>银行账号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @blur="focusout('bank_number')"
              @input="inputField('bank_number')"
              v-model.trim="doctorInfo.bank_number"
              placeholder="请输入银行账号"
            ></el-input>
          </div>
        </div>

        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!Boolean(
                  this.doctorInfo.doctor_work_id_card ||
                    (this.doctorInfo.professional_person &&
                    this.doctorInfo.professional_panoramic)
                )">*</span>专家职称证明（可上传1-3张，请务必包含专家姓名、医院、科室、职称信息，否则无法审核通过）
                <p style="color: #f00;" v-if="doctorInfo.examine_status === 0">人工审核中</p>
          </div>
          <div class="global_text_input doctor-detail-value">
            <el-radio
              v-model.number="doctorInfo.work_type"
              :label="index"
              v-for="(item, index) in config.workType"
              :key="index"
              @change="changeWorkType"
              >{{ item }}</el-radio
            >
          </div>
        </div>
        <div class="doctor-detail-block" v-if="doctorInfo.work_type == 0">
          <div class="doctor-detail-key">医生ID卡</div>
          <div class="doctor-detail-value global_container_center">
            <div
              class="doctor-detail-image-block"
              @click="selectFile('doctor_work_id_card')"
            >
              <img
                :src="doctorInfo.doctor_work_id_card"
                v-if="
                  doctorInfo.doctor_work_id_card && !upload.doctor_work_id_card
                "
                alt=""
              />
              <div class="doctor-detail-image-select" v-else>
                <i
                  v-show="!doctorInfo.doctor_work_id_card"
                  class="el-icon-plus"
                ></i>
                <span>{{
                  getShowName("doctor_work_id_card", "上传医生ID卡")
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="doctor-detail-block" v-if="doctorInfo.work_type == 1">
          <div class="doctor-detail-key">官网全景图（需截到官网网址链接）</div>
          <div class="doctor-detail-value global_container_center">
            <div
              class="doctor-detail-image-block"
              @click="selectFile('professional_panoramic')"
            >
              <img
                :src="doctorInfo.professional_panoramic"
                v-if="
                  doctorInfo.professional_panoramic &&
                  !upload.professional_panoramic
                "
                alt=""
              />
              <div class="doctor-detail-image-select" v-else>
                <i
                  v-show="!doctorInfo.professional_panoramic"
                  class="el-icon-plus"
                ></i>
                <span>{{
                  getShowName("professional_panoramic", "上传官网全景图")
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="doctor-detail-block" v-if="doctorInfo.work_type == 1">
          <div class="doctor-detail-key">官网医生身份信息截图页</div>
          <div class="doctor-detail-value global_container_center">
            <div
              class="doctor-detail-image-block"
              @click="selectFile('professional_person')"
            >
              <img
                :src="doctorInfo.professional_person"
                v-if="
                  doctorInfo.professional_person && !upload.professional_person
                "
                alt=""
              />
              <div class="doctor-detail-image-select" v-else>
                <i
                  v-show="!doctorInfo.professional_person"
                  class="el-icon-plus"
                ></i>
                <span>{{
                  getShowName(
                    "professional_person",
                    "上传官网医生身份信息截图页"
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.job_name">*</span>专家职称（示例：住院/主治/副主任/主任医师）
          </div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select
              v-model="doctorInfo.job_name"
              placeholder="请选择专家职称"
            >
              <el-option
                v-for="(item, index) in config.titles"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span v-show="!doctorInfo.department">*</span>专家科室</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
              @blur="focusout('department')"
              @input="inputField('department')"
              v-model.trim="doctorInfo.department"
              placeholder="请输入专家科室"
            ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key">
            <span v-show="!doctorInfo.professional_cover">*</span>简历图片<span style="color: red"
              >(请上传近期照片，贴合白色轮廓)</span
            >
          </div>
          <div
            class="professional_cover_container global_text_select doctor-detail-value"
          >
            <div
              class="professional_cover"
              @click="selectFile('professional_cover')"
            >
              <img
                :src="doctorInfo.professional_cover"
                v-if="doctorInfo.professional_cover"
                alt=""
              />
              <img
                v-else
                :src="require('@/assets/images/icon/resume_img_have.png')"
                alt=""
              />
            </div>
            <!-- <div class="professional_cover_tips">
              上传近期照片<br>
              贴合白色轮廓
            </div> -->
          </div>
        </div>
        <div class="doctor-detail-block">
          <el-button type="primary" @click.native="doCommit" round>
            <span>提交信息</span>
          </el-button>
        </div>
      </div>
    </item-scroll>

    <image-cropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="config.cropper.cropWidth"
      :CropHeight="config.cropper.cropHeight"
      :flexScale="config.cropper.flexScale"
      :MaxSize="config.cropper.MaxSize"
      :isShowHeadimgCalibration="config.cropper.isShowHeadimgCalibration"
      v-if="cropperManager.url"
    ></image-cropper>
    <input
      type="file"
      v-show="false"
      v-if="cropperManager.resetFile"
      @change="changeFile"
      ref="selectfiles"
    />
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
import ImageCropper from "@/components/unit/ImageCropper";
import weixin from "@/common/weixin";
export default {
  name: "doctor-detail",
  components: {
    ItemScroll,
    ImageCropper,
  },
  data() {
    return {
      config: {
        titles: ["医师(住院医师)", "主治医师", "副主任医师", "主任医师"], // 职称列表
        citys: [],
        workType: ["医生ID卡", "官网医生身份信息截图页"],
        cropper: {
          cropWidth: 300,
          cropHeight: 225,
          flexScale: [4, 3],
          MaxSize:0,
          isShowHeadimgCalibration: false,
        },
      },
      upload: {
        phone: 0,
        id_card_number: 0,
        bank_number: 0,
        id_card_front: 0,
        id_card_reverse: 0,
        doctor_work_id_card: 0,
        professional_person: 0,
        professional_panoramic: 0,
        // professional_cover: 0,
      },
      cropperManager: {
        key: "",
        resetFile: true,
        url: "",
      },
      doctorInfo: {
      },
      doctorInfoModel:{
        id: "",
        // openid: "",
        name: "",
        province: "",
        city: "",
        hospital: "",
        // hospital_code: "",
        department: "",
        examine_status:"",
        phone: "",
        id_card_number: "",
        bank_province: "",
        bank_city: "",
        bank_name: "",
        bank_name_point: "",
        bank_number: "",
        id_card_front: "",
        id_card_reverse: "",
        // meet_count: "",
        work_type: 0,
        doctor_work_id_card: "",
        // isspecial: 0,
        professional_title: "",
        professional_person: "",
        professional_panoramic: "",
        job_name: "",
        professional_cover: ""
      },
      dealWithKey: "",
      curbase64: "",
      resetFile: true,
      changeImg: {},
      backInfo: {},
      isloading: false,
      loadingText:"数据上传中",
      mergeFields: {
        province: "",
        city: "",
        bank_name: "",
        deposit_bank: "",
      },
    };
  },
  watch: {
    doctorInfo: {
      handler(newValue, oldValue) {
        this.$tools.setLocalCookie("doctorInfo", newValue);
        this.$tools.setLocalCookie("createDoctorInfo", newValue);
        // 处理值变化
      },
      immediate: false,
      deep: true,
    },
  },

  mounted() {
    setTimeout(() => {
      this.$refs.itemScroll.initScroll();
    }, 500);
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    bankCitys() {
      if (!this.doctorInfo.bank_province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.bank_province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
    citys() {
      if (!this.doctorInfo.province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  async created() {
    let doctorInfo = this.$tools.getLocalCookie("doctorInfo");
    let createDoctorInfo = this.$tools.getLocalCookie("createDoctorInfo") || null;
    if(createDoctorInfo != null&&doctorInfo.name == createDoctorInfo.name){
      this.doctorInfo = createDoctorInfo;
    }else{
      this.doctorInfo = Object.assign(
        { work_type: 0 },
        this.doctorInfo,
        doctorInfo
      );
    }
    this.config.citys = await this.$tools.getCityConfig();
  },
  methods: {
    changeProvince(key) {
      if (key == "bank") {
        this.doctorInfo.bank_city = "";
      } else {
        this.doctorInfo.city = "";
      }
    },
    receiveData(data) {
      this.cropperManager.url = "";
      if(!data.url) return;
      this.doctorInfo[this.cropperManager.key] = data.url;
      this.upload[this.cropperManager.key] = 0;
      if (
        [
          "doctor_work_id_card",
          "professional_panoramic",
          "professional_person",
        ].includes(this.cropperManager.key)
      ) {
        this.loadJobName(data.url);
      }
    },
    getShowName(key, label) {
      return this.doctorInfo[key] ? "已上传" : label;
    },
    getKey(key) {
      if (this.config.types[key]) return this.config.types;
      if (this.config.merges.indexOf(key) > -1) return this.mergeFields;
      return this.doctorInfo;
    },
    loadJobName(image) {
      let url = this.$tools.getURL(this.$urls.admin.job, { url: image });
      this.$axios.get(url).then((res) => {
        if (res.job_name) this.doctorInfo.job_name = res.job_name;
      });
    },
    loadData() {
      let url = this.$tools.getURL(this.$urls.doctor.getAllDoctorInfo, {
        id: this.doctorInfo.id,
      });
      this.$axios.get(url).then((res) => {
        this.doctorInfo = { ...this.doctorInfo, ...res.data[0] };
        this.backInfo = JSON.parse(JSON.stringify(this.doctorInfo));
        for (let key in this.upload) {
          this.upload[key] = this.doctorInfo[key] ? 1 : 0;
          this.doctorInfo[key] = this.doctorInfo[key] || "";
          let tag = this.doctorInfo[key].replace(/./g, "*");
          tag = tag.substr(0, tag.length - 7);
          this.doctorInfo[key] = `${this.doctorInfo[key].substr(
            0,
            3
          )}${tag}${this.doctorInfo[key].substr(-4)}`;
        }
        // this.backInfo = { ...this.doctorInfo };
      });
    },
    inputField(key) {
      switch (key) {
        case "id_card_number":
          this.doctorInfo[key] = this.doctorInfo[key].replace(/\D\W/g, "").replace("*", "");
          break;
        case "phone":
          this.doctorInfo[key] = this.doctorInfo[key].replace(/\D/g, "");
          break;
        case "bank_number":
          this.doctorInfo[key] = this.doctorInfo[key].replace(/\D/g, "");
          break;
        default:
          this.doctorInfo[key] = this.doctorInfo[key].replace("*", "");
          break;
      }
    },
    focusout(key) {
      if (this.isHide(this.doctorInfo[key])) {
        this.doctorInfo[key] = this.backInfo[key];
      }
    },
    async changeFile(event) {
      this.isloading = true;
      this.loadingText = "正在读取图片";
      let file = event.target.files[0];
      try {
        let fileInfo = await this.$tools.compressImage(file, true);
        this.isloading = false;
        this.loadingText = "";
        this.cropperManager.resetFile = false;
        this.cropperManager.url = fileInfo;
        this.$nextTick(() => {
            this.cropperManager.resetFile = true
            this.$nextTick(() => {
                this.$refs.imageCropper.setData(this.cropperManager.url);
            });
        })
      }catch{
        this.isloading = false;
        this.loadingText = "";
      }
      // let file = event.target.files[0];
      // if (!file) return;
      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = async (item) => {
      //   this.cropperManager.url = item.target.result;
      //   this.cropperManager.resetFile = false;
      //   // console.log(this.cropperManager.url)
      //   this.$nextTick(() => {
      //     this.cropperManager.resetFile = true;
      //     console.log(this.$refs.imageCropper, this.cropperManager.resetFile);
      //     this.$refs.imageCropper.setData(this.cropperManager.url);
      //   });
      // };
    },
    changeWorkType() {
      this.$refs.itemScroll.initScroll();
      // this.doctorInfo.doctor_work_id_card = "";
      // this.doctorInfo.professional_panoramic = "";
      // this.doctorInfo.professional_person = "";
    },

    selectLocalFile() {
      this.$refs.selectfiles.click();
    },
    selectFile(key) {
      if (this.loading || !this.resetFile) return;
      if (key == "professional_cover") {
        this.config.cropper = {
          cropWidth: 225,
          cropHeight: 225,
          flexScale: [1, 1],
          MaxSize:2,
          isShowHeadimgCalibration: true,
        };
      } else {
        this.config.cropper = {
          cropWidth: 300,
          cropHeight: 189,
          flexScale: [8.56, 5.4],
          MaxSize:0,
          isShowHeadimgCalibration: false,
        };
      }
      this.cropperManager.key = key;
      this.selectLocalFile(key);
      // if (this.$tools.isLocal()) {
      //   this.selectLocalFile(key);
      // } else {
      //   this.loading = true;
      //   weixin
      //     .selectFile(1, 999999)
      //     .then((files) => {
      //       setTimeout(() => {
      //         this.loading = false;
      //       }, 1000);
      //       this.cropperManager.url = files[0];
      //       this.$nextTick(() => {
      //         this.$refs.imageCropper.setData(this.cropperManager.url);
      //       });
      //     })
      //     .catch((err) => {
      //       setTimeout(() => {
      //         this.loading = false;
      //       }, 1000);
      //     });
      // }
    },
    checkPhone() {
      this.doctorInfo.phone = this.doctorInfo.phone
        .replace(/\D/g, "")
        .substr(0, 11);
    },
    checkCard() {
      this.doctorInfo.id_card_number = this.doctorInfo.id_card_number
        .replace(/[^\dxX]/g, "")
        .substr(0, 18);
    },
    checkNumber() {
      this.doctorInfo.bank_number = this.doctorInfo.bank_number
        .replace(/\D/g, "")
        .substr(0, 30);
    },
    isHide(data) {
      return (data || "").includes("*");
    },
    checkData() {
      if (!this.doctorInfo.hospital) {
        this.$tips.error({ text: "请输入医院名称" });
        return false;
      }
      if (!this.doctorInfo.province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.doctorInfo.city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.doctorInfo.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      //上传了医生ID卡或者官网信息，则职称必填
      if (((this.doctorInfo.work_type == 0 && this.doctorInfo.doctor_work_id_card ||  
            this.doctorInfo.professional_panoramic && this.doctorInfo.professional_person && this.doctorInfo.work_type == 1))&&
            !this.doctorInfo.job_name) {
          this.$tips.error({ text: "请选择职称" });
          return false;
      }
      if (this.doctorInfo.phone&&!this.isHide(this.doctorInfo.phone)) {
        if (!this.doctorInfo.phone) {
          this.$tips.error({ text: "请输入手机号" });
          return false;
        }
        if (!/^1\d{10}$/g.test(this.doctorInfo.phone)) {
          this.$tips.error({ text: "请输入格式正确的手机号" });
          return false;
        }
      }
      if (this.doctorInfo.id_card_number&&!this.isHide(this.doctorInfo.id_card_number)) {
        if (!this.doctorInfo.id_card_number) {
          this.$tips.error({ text: "请输入身份证号" });
          return false;
        }
        let reg =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
        if (!reg.test(this.doctorInfo.id_card_number)) {
          this.$tips.error({ text: "身份证号不合法" });
          return false;
        }
      }
      // if (!this.doctorInfo.bank_name) {
      //   this.$tips.error({ text: "请输入银行" });
      //   return false;
      // }
      // if (!this.doctorInfo.bank_province) {
      //   this.$tips.error({ text: "请选择银行省份" });
      //   return false;
      // }
      // if (!this.doctorInfo.bank_city) {
      //   this.$tips.error({ text: "请选择银行城市" });
      //   return false;
      // }
      // if (!this.doctorInfo.bank_name_point) {
      //   this.$tips.error({ text: "请输入开户银行" });
      //   return false;
      // }
      if (this.doctorInfo.bank_number&&!this.isHide(this.doctorInfo.bank_number)) {
        if (!this.doctorInfo.bank_number) {
          this.$tips.error({ text: "请输入银行账号" });
          return false;
        }
        if (
          !/(^\d{16}$)|(^\d{17}$)|(^\d{18}$)|(^\d{19}$)/.test(
            this.doctorInfo.bank_number
          )
        ) {
          this.$tips.error({ text: "错误的银行账号" });
          return false;
        }
      }

      // if (!this.isHide(this.doctorInfo.id_card_front)) {
      //   if (!this.doctorInfo.id_card_front) {
      //     this.$tips.error({ text: "请上传身份证正面" });
      //     return false;
      //   }
      // }
      // if (!this.isHide(this.doctorInfo.id_card_reverse)) {
      //   if (!this.doctorInfo.id_card_reverse) {
      //     this.$tips.error({ text: "请上传身份证反面" });
      //     return false;
      //   }
      // }
      // if (this.doctorInfo.work_type == 0) {
      //   if (
      //     !this.isHide(this.doctorInfo.doctor_work_id_card) &&
      //     !this.doctorInfo.doctor_work_id_card
      //   ) {
      //     this.$tips.error({ text: "请上传医生ID卡" });
      //     return false;
      //   }
      // }
      // if (this.doctorInfo.work_type == 1) {
      //   if (
      //     !this.isHide(this.doctorInfo.professional_panoramic) &&
      //     !this.doctorInfo.professional_panoramic
      //   ) {
      //     this.$tips.error({ text: "请上传官网全景图" });
      //     return false;
      //   }
      //   if (
      //     !this.isHide(this.doctorInfo.professional_person) &&
      //     !this.doctorInfo.professional_person
      //   ) {
      //     this.$tips.error({ text: "请上传官网医生身份信息截图页" });
      //     return false;
      //   }
      // }
      // if (!this.doctorInfo.department) {
      //   this.$tips.error({ text: "请输入专家科室" });
      //   return false;
      // }
      // if (!this.doctorInfo.job_name) {
      //   this.$tips.error({ text: "请选择职称" });
      // }
      // if (!this.doctorInfo.professional_cover) {
      //   this.$tips.error({ text: "请上传简历图片" });
      //   return false;
      // }
      return true;
    },
    //申请人工审核
    applyAudit(){
      this.$axios.post(this.$urls.admin.applyAudit,{doctor_id:this.doctorInfo.id}).then(res=>{
        this.$tips.success({text:"申请人工审核成功"});
        this.getApplyAuditStatus();
      }).catch(err=>{
        this.$tips.error({text:"申请人工审核失败"});
        console.log(err)
      })
    },
    //获取人工审核状态
    getApplyAuditStatus(){
      let url = this.$tools.getURL(this.$urls.admin.examineStatus,{doctor_id:this.doctorInfo.id,all_doctor_id:this.doctorInfo.all_doctor_id || ""});
      this.$axios.get(url).then(res=>{
        this.doctorInfo.examine_status = res.examine_status;
        this.$refs.itemScroll.initScroll();
      }).catch(err=>{
        console.log(err)
      })
    },
    doCommit() {
      if (!this.checkData() || this.isloading) return;
      this.isloading = true;
      let data = {...this.doctorInfoModel,...this.doctorInfo}
      if(data.id === null) data.id = "";
      this.$axios
        .patch(this.$urls.admin.doctor, {...data})
        .then((res) => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
          this.doctorInfo.id = res.data?.doctor_id || res.doctor_id;
          if(res.code == 400){
            if(res.key == "professionalProve"){
              this.$confirm(res.msg,"提示",{
                confirmButtonText: "我上传的信息齐全，申请人工审核",
                cancelButtonText: "点击补充",
                center: true,
                type: "warning",
                cancelButtonClass:"docterdetail_cancelButtonClass",
                customClass: "docterdetail_confirm_customClass",
              }).then(() => {
                this.applyAudit();
              }).catch(() => {});
            }else{
              let text = res.msg || this.commitSingleField[res.key];
              this.$tips.error({ text: text });
              this.uploadingSingleFieldErr = true;
            }
            return
          }
          localStorage.removeItem("doctorInfo");
          this.$tools.setLocalCookie("status", 2);
          this.$tools.setLocalCookie("createDoctorInfo", {});
          this.$router.back();
        })
        .catch((err) => {
          console.log(err)
          // //以下报错提示调试用,没有errcode,不是服务器返回422或者500(表示then中的代码出错)
          // if(!err.errcode){
          //   this.$tips.error({ text: "新增医生信息出错" });
          // }
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },
  },
};
</script>

<style>
.doctor-detail-container {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 4vw;
  background: #f5f5f5;
  overflow: hidden;
}
.doctor-detail-main {
  height: auto;
}
.doctor-detail-block {
  margin-bottom: 4vw;
}
.doctor-detail-block .el-button {
  width: 100%;
}
.doctor-detail-key {
  color: #6f6f6f;
}
.doctor-detail-key span {
  color: red;
}
.doctor-detail-key {
}
.doctor-detail-value {
  margin-top: 2vw;
}
.doctor-detail-name .el-input.is-disabled .el-input__inner {
  background: #ccc;
  color: #000;
}
.doctor-detail-name2 .el-input.is-disabled .el-input__inner {
  background: #fff;
}
.doctor-detail-name {
  /* padding:0; */
  background: #ccc;
  border: 1px solid #ccc;
}
.doctor-detail-image-block {
  width: 85.6vw;
  height: 54vw;
  background: #fff;
  border: 1px solid #9f9f9f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9f9f9f;
}
.doctor-detail-image-block img {
  width: 85.6vw;
  height: 54vw;
}
.doctor-detail-image-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.doctor-detail-image-select i {
  font-size: 8vw;
  line-height: 12vw;
}
.doctor-detail-container .customButton_container {
  color: #fff;
}
.professional_cover_container {
  display: flex;
}
.professional_cover {
  width: 32vw;
  height: 32vw;
  background-color: #c9c5c5;
}
.professional_cover img {
  width: 100%;
  height: 100%;
}
.professional_cover_tips {
  margin-left: 4vw;
  flex: 1 0 0;
  color: #a40250;
  font-size: 8vw;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.docterdetail_confirm_customClass {
  width: 90%;
}
.docterdetail_confirm_customClass .el-message-box__message,.docterdetail_confirm_customClass .el-message-box__title {
  font-size: 4vw;
}
.docterdetail_confirm_customClass .el-message-box__btns button{
  width: 100%;
}
.docterdetail_confirm_customClass .el-message-box__btns button:nth-child(2){
  margin-top: 4vw;
  margin-left: 0;
}
.docterdetail_cancelButtonClass{
  background-color:#008f3d;
  color: #fff;
}
</style>